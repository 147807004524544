:root {
    --font-family-main: Montserrat, Helvetica, Arial, sans-serif;

    --font-size-x: .5rem;
    --font-line-x: .75rem;
    --font-x: var(--font-size-x) / var(--font-line-x) var(--font-family-main);
  
    --font-size-s: 0.8rem;
    --font-line-s: 1.25rem;
    --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);
  
    --font-size-m: 1rem;
    --font-line-m: 1.5rem;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);
  
    --font-size-l: 1.5rem;
    --font-line-l: 2rem;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

    --font-size-xl: 2.5rem;
    --font-line-xl: 2.5rem;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

    --bg-color-primary: #f9f9f9;
    --bg-color-secondary: #f1f1f1;
    --bg-color-inverted: #404040;

    --text-color-primary: #404040;
    --text-color-primary-inverted: #ffffff;

    --color-primary: #ff777d;
    --color-secondary: #c7c7c7;

    --brad: 20px;

    --brad-bottom: 20px 20px  0 0;
  
  }

  