.QuestionsSection {
  border-radius: var(--brad);
  background-color: var(--bg-color-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;

  &>h1 {
    font: var(--font-xl);
    font-weight: 600;
    padding: 60px;
    text-align: center;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 0 40px 40px 40px;
  gap: 20px;

  &>div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &>div>div:nth-child(1) {
      min-height: 110px;
    }
  }
}


@media (max-width: 1023px) {
  .content {
    grid-template-columns: 1fr;
    gap: 10px;
  }

}

@media (max-width: 610px) {
  .QuestionsSection>h1 {
    font: var(--font-l);
    font-weight: 600;
    padding: 40px;
  }

  .content {
    padding: 10px;

    & p {
      font: var(--font-m) !important;
      font-weight: 600 !important;
    }

    & > div > div > div:nth-child(1) {
      min-height: auto;
    }
  }

}