.ServicesSection {

  border-radius: var(--brad);
  background-color: var(--color-primary);
  padding: 40px;

  background-image: url('../../assets/img/feathers.png');
  background-position: top;
  background-size: contain;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  & h3 {
    text-align: center;
    padding-bottom: 20px;
  }

  &>div {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 8px;
    background-color: var(--bg-color-primary);
    border-radius: var(--brad);

    &>div:nth-last-child(1)>span {
      border: none;
    }
  }
}


.header {
  display: flex;
  flex-direction: column;
  color: var(--text-color-primary-inverted);
  padding: 60px;
  gap: 20px;
  text-align: center;

  &>h1 {
    font: var(--font-xl);
    font-weight: 600;
  }

  &>h2 {
    font: var(--font-l);
  }

  &>div {
    padding: 20px;
    background-color: var(--bg-color-primary);
    border-radius: var(--brad);
    color: var(--text-color-primary);
    font-weight: 500;
  }
}

.contacts {
  display: flex;
  gap: 1em;
  font: var(--font-l);
  font-weight: 700;
  justify-content: center;
  align-items: center;

  & a {
    align-self: center;
    color: var(--text-color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & img {
    height: 30px;
    width: 30px;
  }
}


@media (max-width: 1023px) {
  .content {
    grid-template-columns: 1fr;
  }

  .header {
    padding: 20px 0;
  }

}

@media (max-width: 610px) {

  .ServicesSection {
    padding: 15px;
  }

  .content {
    grid-template-columns: 1fr;

    &>div {
      padding: 10px;
    }
  }

  .header {

    &>h1 {
      font: var(--font-l);
      font-weight: 600;
    }

    &>h2 {
      font: var(--font-m);
    }
  }

  .phoneIcon {
    display: flex;
  }

  

}