.Accordeon {
  background: white;
  border-radius: var(--brad);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

}


.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: var(--brad);
  width: 100%;
  cursor: pointer;

  & > p {
    font: var(--font-l);
    font-weight: 700;
  }
}

.icon {
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-width: 0 0 3px 3px;
  border-style: solid;
  border-color: var(--color-primary);
  margin: 10px;
  transform: rotate(-135deg);
  margin-left: auto;
}



.body {
  display: grid;
  grid-template-rows: 0;
  overflow: hidden;
  transition: grid-template-rows 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.active {

  & .icon {
    transform: rotate(-45deg);
  }

  & .body {
    grid-template-rows: 1fr;
    max-height: none;
    opacity: 100%;
    padding: 0 50px 20px 20px;
  }

}

