.BenefitsSection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr) minmax(290px, 1fr));
  gap: 10px;

  & > div {
    display: grid;
    gap: 10px;
  }


}

@media (max-width: 650px) {

  .BenefitsSection {
    grid-template-columns: repeat(auto-fill,  minmax(290px, 1fr));
  }
  
}