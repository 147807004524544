@import "reset";
@import "./variables/global";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  
  &:focus {
    outline-color: black;
  }
}

a {
  text-decoration: none;
  white-space: nowrap;
}

.app {
  font: var(--font-m);
  row-gap: 10px;

  background: var(--bg-color);
  color: var(--text-color-primary);

  min-height: 100vh;
  font-weight: 500;

  display: grid;
  grid-template-rows: 70px 1fr auto;
  
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

main {
  background-color: var(--bg-color-first);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width : 1279px) {

  .container {
    margin: 0 10px;
    max-width: calc(100vw - 20px);
  }

}