.ListItem {
    display: flex;
  
    & > span {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px dashed var(--color-secondary);
      align-items: end;
  
      
      & > p:nth-last-child(1) {
        font-weight: 700;
        text-wrap: nowrap;
        margin-left: 10px;
        white-space: nowrap;
      }
      
      & > p:nth-child(1) {
        text-wrap: pretty;
      }
      
    }
      
  }