.HeroSection {
    min-height: 500px;
    background-image: url('../../assets/img/hero.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: var(--brad);
    display: grid;
    grid-template-columns: 1fr 45%;
    background-color: white;

    & h1 {
        font: var(--font-xl);
        font-weight: 600;
    }

    & a {
        background: var(--color-primary);
        border-radius: 10px;
        border: none;
        padding: 10px 20px;
        color: var(--text-color-primary-inverted);
        font-weight: 600;
        width: 100%;
    }

}

.info {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 2em;
}

@media (max-width: 959px) {
    .HeroSection {
        min-height:400px;
        background-size: cover;
        background-position-x: -200px;
        grid-template-columns: 1fr;
        grid-template-rows: 0 1fr;
    }
    .info {
        padding: 1em;
        max-width: 500px;
        margin: 0 auto;
        gap: 2em;
        background: linear-gradient(90deg, rgba(255,255,255, 0) 0%,  rgba(255,255,255, 0.9) 50%, rgba(255,255,255, 0) 100%,);
    }



}