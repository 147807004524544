.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px;
    background-color: var(--bg-color-inverted);
    border-radius: var(--brad-bottom);
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: var(--font-l);
    font-weight: 500;
    color: var(--text-color-primary-inverted);

    & :nth-child(2) {
        font: var(--font-x);
    }
}

.phone {
    text-decoration: none;
    color: var(--text-color-primary-inverted);
    font: var(--font-l);

}

.address {
    color: var(--text-color-primary-inverted);
    font-weight: 400;
    text-align: center;

    white-space: normal;
}

.contacts {
    display: flex;
    gap: 1em;

    & a {
        align-self: center;
        color: var(--text-color-primary-inverted);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & img {
        height: 30px;
        width: 30px;
        filter: invert(1)
    }
}

