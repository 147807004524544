.InfoCardSmall {
    border-radius: var(--brad);
    min-height: 200px;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    & span {
        display: flex;
        align-items: end;
        height: 100%;
        background-image: linear-gradient(0deg, rgba(255,255,255, 0.8) 0%, rgba(255,255,255, 0) 100%);
        &>p {
            padding: 1.5rem;
            font-weight: 700;
            font-size: 1.5rem;
            width: 100%;
        }
    }
}