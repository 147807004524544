
.Header {
    box-shadow:  0px 0px 20px 0px rgb(228, 228, 228);

        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

    border-radius: var(--brad);
    padding: 0 40px;
    background-color: var(--bg-color-primary);

    & a {
        color: var(--text-color-primary)
    }
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: var(--font-l);
    font-weight: 500;

    & :nth-child(2) {
        font: var(--font-x);    
    }
}

.contacts {
    display: flex;
    gap: 1em;

    & a {
        align-self: center;
        color: var(--text-color-primary);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & img {
        height: 30px;
        width: 30px;
    }
}

.phoneIcon {
    display: none !important;
}

@media (max-width: 500px) {

    .phoneIcon {
        display: flex !important;
    }

    .phone {
        display: none !important;
    }
    .Header {

        padding: 0 10px;

    }
}